/* Importing Bootstrap SCSS file. */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/scss/bootstrap";
@import "~ngx-toastr/toastr";
@import "~@busacca/ng-pick-datetime/assets/style/picker.min.css";

$font-family: "Roboto", sans-serif;
$primary-color: #22a5de;
$sub-title-color: #03a9f4;
$azul-color: #005bef;
$primary-font-size: 13px;
$off-white-color: #f5f5f5;

body {
  font-family: $font-family;
}

.primary-color {
  background: $primary-color;
}

.sub-title-color {
  color: $sub-title-color;
}

label {
  font-size: 13px !important;
}

.text-end {
  text-align: end !important;
}

.btn-primary {
  font-size: 14px;
  background: #22a5de;
  border: unset;
  border-radius: 5px;
  padding: 8px 30px;

  &:hover {
    background: #22a5deb5;
    border-color: #22a5deb5;
  }

  &:active {
    background-color: #22a5debf !important;
    border-color: #22a5debf !important;
  }

  &:focus {
    box-shadow: none !important;
    outline: unset !important;
    background-color: #22a5debf !important;
    border-color: #22a5debf !important;
  }
}

.btn {
  color: #fff !important;
  border-radius: 2px !important;
  border: none;
  padding: 7px 30px;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
}

.icon-action span {
  cursor: pointer;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #22a5de;
  color: white;
  text-align: center;

  p {
    margin-top: 16px;
  }
}

.navbar {
  background: $primary-color;
  padding: 10px !important; 
  position: relative;
}

.user-log-row {
  background: #d8d8d8;
  width: 100%;
  // height: 70px;
  margin-left: 0px;
  margin-right: 0px;

  h5 {
    margin-top: 14px;
    color: grey;
  }

  a {
    padding: 12px 0px 10px 30px;
    color: grey;
    float: right;
    cursor: pointer;
  }
}

.index-pgs {
  padding: 50px 17px;
  display: grid;
  .fa {
    font-size: 25px;
    margin-right: 10px;
  }
  .pg-name {
    vertical-align: text-bottom;
  }
}

.header-title {
  .title-line {
    border-bottom: 2px solid #22a5de78;
    margin-bottom: 27px;
  }

  h5 {
    font-weight: 600;
  }

  a {
    float: right;
    color: #22a5de;
  }
}

table {
  font-size: 13px;
  border: 1px solid #dee2e6;

  tr:hover {
    color: dimgrey;
    cursor: pointer;
  }

  th,
  td {
    vertical-align: middle !important;
    padding: 3px !important;
  }
}

.dropdown-item {
  font-size: 14px;
  padding: 2px 10px;
}

.dropdown-toggle,
.dropdown-toggle:not(:disabled):not(.disabled):active,
.dropdown-toggle:focus,
.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:focus {
  border: 1px solid #ced4da;
  background: white;
  color: gray;
  width: 100%;
  outline: unset;
  box-shadow: unset;
}

.pagination .page-link {
  font-size: 11px;
}

.page-item.active .page-link {
  z-index: 0;
}

.card-body {
  padding: 10px !important;
}

.card-header {
  background-color: #fafafa;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

p {
  font-size: 12px;
}

.m-t-11 {
  margin-top: 11px;
}

.m-t-1 {
  margin-top: 1px;
}

.p-l-5 {
  padding-left: 5px;
}

.page-wrapper {
  padding: 15px;
}

.shadow {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05), 0 20px 48px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.1) !important;
}

.logo {
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.logo-icon {
  height: 35px;
  margin-top: 3px;
}

app-login {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  height: 100vh;
}

.div-spinner {
  width: 100%;
  height: 100%;
  left: 0%;
  z-index: 100000;
  position: fixed;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.08);
}

.spinner {
  width: 60px !important;
  height: 60px !important;
  margin-top: 22%;
}

.disable-checkmark {
  background-color: #e0e0e0 !important;
}

.fa-search {
  color: #22a5de;
  padding: 7px;
  font-size: 18px !important;
  margin-left: 5px;
  cursor: pointer;
}

.fa-sort-desc {
  margin-left: 5px;
  vertical-align: top;
  cursor: pointer;
}

.down-arrow {
  border-radius: 3px;
  padding: 0px 7px 0px 3px;
  height: 20px;
  margin-top: 1px;
}

.doc-find {
  font-size: 12px;
  color: gray;
  padding: 4px;
}

#scrollbar::-webkit-scrollbar-track {
  background-color: white;
}

#scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
  box-shadow: unset;
}

.ng-select {
  width: 100%;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
  font-size: 13px;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #22a5de;
}

.ng-select .ng-clear-wrapper .ng-clear {
  margin-top: 6px;
}

.ng-dropdown-panel {
  font-size: 13px;
}

.date-field {
  width: 155px;
  height: 36px;
}

.filter-list {
  ng-select {
    display: inline-grid;
    float: right;

    &:nth-child(1) {
      width: 60px;
    }

    &:nth-child(2) {
      width: 130px;
      margin-right: 10px;
    }
  }
}

// responsive media queries
@media screen and (max-width: 1366px) {
  .module-content {
    max-height: 385px !important;
  }
}

@media screen and (max-width: 1024px) {
  .md-mt-3 {
    margin-top: 20px;
  }
}

@media (max-width: 560px) {
  .s-w-30 {
    width: 30%;
  }

  .s-w-50 {
    width: 50%;
  }

  .s-w-70 {
    width: 70%;
  }

  .s-m-t-10 {
    margin-top: 10px;
  }

  .s-m-b-10 {
    margin-bottom: 10px;
  }

  .user-log-row,
  .header-title {
    width: 527px;
  }

  .pagination-div {
    margin-bottom: 60px;
    width: 527px;
  }
}

.owl-dt-calendar-table {
  border: none;

  .owl-dt-calendar-cell {
    padding: 20px !important;
    .owl-dt-calendar-cell-content {
      font-size: 0.9em;
    }
    .owl-dt-calendar-cell-selected {
      background-color: #22a5de;
    }
  }
}

.owl-dt-calendar {
  display: unset;
}

.text-danger {
  font-size: 11px;
}

.ngx-pagination {
  font-size: 12px;
  padding: 0;
  a:hover {
    background: #d5f2ff !important;
  }
}

pre {
  font-family: $font-family;
  font-size: 13px;
  margin: 0 0 5px 0;
  white-space: pre-wrap;
}

.pagination-div {
  position: absolute;
  margin-bottom: 70px;
  width: 100%;
}

#scroll-y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#scroll-y::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#scroll-y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #22a5de;
}

.tree-view-container .custom-control-label::before,
.custom-control-label::after {
  width: 12px;
  height: 12px;
}

.red-clr {
  color: red;
}

.w-5 {
  width: 5%;
}

.sort-arrow {
  vertical-align: bottom;
  padding-left: 5px;
}

.reportClass{
  height: 500px !important;
}
.dashboard-tile-blue {
  background: #22a5de;
  border: unset;
  border-radius: 10px !important;
  color: white;
  padding: 20px;
  margin: 8px;
  text-align: left;
  .btn {
    font-size: 24px;
    padding-left: 0px;
    padding-right: 0px;
    span{
      vertical-align: middle;
      margin: auto;
    }
    .fa {
      font-size: 50px;
    }
  }
}

.dashboard-tile-grey {
  background: #e4e4e4;
  border: unset;
  border-radius: 10px !important;
  color: black;
  padding: 20px;
  margin: 8px;
  text-align: left;
  .btn {
    font-size: 24px;
    padding-left: 0px;
    padding-right: 0px;
    height: 6rem;
    width: 100%;
    span{
      vertical-align: middle;
      margin: auto;
    }
    .fa {
      font-size: 50px;
    }
  }
}

.d-grid{
  display: grid;
}

//trabajadore page
.btn-radius-left {
  font-size: 13px;
  padding: 0px 7px;
  height: 20px;
  margin-top: 8px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: 5px;
}

.btn-radius-right {
  font-size: 13px;
  padding: 0px 7px;
  height: 20px;
  margin-top: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.search-input {
  font-size: 13px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: unset;
  min-height: 36px;
  height: 36px;
  padding-left: 10px;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  &:focus-visible{
    outline: unset !important;
  }
}

.search-icon-btn{
  background-color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #ccc;
  min-height: 36px;
  height: 36px;
  align-items: center;
}

.select-input{
  font-size: 13px;
  min-height: 36px;
  height: 36px;
  &:focus{
    box-shadow: unset;
  }
}

.edit-row span{
  color: $primary-color;
}

.border {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pagination-font {
  font-size: 13px;
}

.tbl-row-action {
  color: $azul-color;
}

.tbl-row-cancel {
  color:red;
}


.tbl-trabajadore tbody tr {
  td:nth-child(9),
  td:last-child {
    color: $primary-color;
    cursor: pointer;
    padding-left: 20px !important;
  }
}

.form-control {
  font-size: $primary-font-size;
}

.form-control-sm {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.time-labels ul {
  display: contents;
  li {
    margin-right: 5px;
  }
}


@media (max-width: 560px) {
  .s-w-45 {
    width: 45%;
  }
  .card {
    width: 335px;
  }
  .emp-reg-card {
    top: 2% !important;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-top: 20px;
  }
  .btn-confirm {
    width: 100%;
  }
  #scroll-y {
    overflow-y: scroll;
    max-height: 500px;
  }
  full-calendar {
    max-height: 428px;
  }
  .time-labels ul {
    display: block;
  }
  .day-title {
    justify-content: center;
    margin-bottom: 10px;
  }
}

.emp-reg-card {
  top: 5%;
}

.center-content {
  position: fixed;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#scroll-y {
  overflow-y: scroll;
  max-height: 377px;
  &::-webkit-scrollbar-track {
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar {
    height: 5px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $sub-title-color;
    box-shadow: unset;
  }
}

.iframe-boder {
  height: 100% !important;
}